import { useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { useNavigationPermissionsContext } from '@sevenrooms/mgr-core'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { accessRulesMessages } from '../locales/accessRules.locales'

interface ViewReservationWidgetButtonProps {
  isWeekView?: boolean
}

export function ViewReservationWidgetButton({ isWeekView }: ViewReservationWidgetButtonProps) {
  const { formatMessage } = useLocales()
  const { venueUrlKey } = useAppContext()
  const permissions = useNavigationPermissionsContext()

  const reservationWidgetLink = `${window.location.origin}/reservations/${venueUrlKey}`

  return permissions?.canViewReportingFull ? (
    <Button
      size={isWeekView ? 's' : 'm'}
      variant="secondary"
      onClick={() => window.open(reservationWidgetLink, '_blank')}
      data-test="view-reservation-widget-button"
    >
      {formatMessage(accessRulesMessages.viewReservationWidget)}
    </Button>
  ) : null
}

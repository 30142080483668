import { useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { useNavigationPermissionsContext } from '@sevenrooms/mgr-core'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { accessRulesMessages } from '../locales/accessRules.locales'

interface ViewActivityLogButtonProps {
  isWeekView?: boolean
}

export function ViewActivityLogButton({ isWeekView }: ViewActivityLogButtonProps) {
  const { formatMessage } = useLocales()
  const { venueUrlKey } = useAppContext()
  const permissions = useNavigationPermissionsContext()

  const activityLogLink = `${window.location.origin}/manager/${venueUrlKey}/activitylog?selectedCategory=ACCESS`

  return permissions?.canViewReportingFull ? (
    <Button
      size={isWeekView ? 's' : 'm'}
      variant="secondary"
      onClick={() => window.location.assign(activityLogLink)}
      data-test="view-activity-log-button"
    >
      {formatMessage(accessRulesMessages.viewActivityLog)}
    </Button>
  ) : null
}

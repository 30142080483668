import { useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { HStack } from '@sevenrooms/core/ui-kit/layout'
import { accessRulesMessages } from '../locales/accessRules.locales'
import { ViewActivityLogButton } from './ViewActivityLogButton'
import { ViewReservationWidgetButton } from './ViewReservationWidgetButton'

interface WeekViewActionButtonsProps {
  date: Date
  onClick: (date: Date) => void
}

export function WeekViewActionButtons({ date, onClick }: WeekViewActionButtonsProps) {
  const { formatMessage } = useLocales()

  return (
    <HStack mt="xs" mb="xs" ml="xs" mr="sm" spacing="sm">
      <ViewActivityLogButton isWeekView />
      <ViewReservationWidgetButton isWeekView />
      <Button size="s" onClick={() => onClick(date)} icon="VMSWeb-edit" data-test="create-access-rule-button">
        {formatMessage(accessRulesMessages.createAccessRule)}
      </Button>
    </HStack>
  )
}

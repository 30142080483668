import { defineMessages } from '@sevenrooms/core/locales'

export const accessRulesMessages = defineMessages({
  switchToWeekView: {
    id: 'accessRules.switchToWeekView',
    defaultMessage: 'Full Week View',
  },
  switchToListView: {
    id: 'accessRules.switchToListView',
    defaultMessage: 'List View',
  },
  createAccessRule: {
    id: 'accessRules.createAccessRule',
    defaultMessage: 'Create Access Rule',
  },
  viewActivityLog: {
    id: 'accessRules.viewActivityLog',
    defaultMessage: 'View Activity Log',
  },
  viewReservationWidget: {
    id: 'accessRules.viewReservationWidget',
    defaultMessage: 'View Reservation Widget',
  },
} as const)

import { useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Flex } from '@sevenrooms/core/ui-kit/layout'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { accessRulesMessages } from '../locales/accessRules.locales'

export function SwitchToListViewButton({ dateString }: { dateString: string }) {
  const { formatMessage } = useLocales()
  const { venueUrlKey } = useAppContext()

  const link = `${window.location.origin}/manager/${venueUrlKey}/manage/capacity/accessrules/day?d=${dateString}`

  return (
    <Flex alignItems="center" mr="auto" ml="auto">
      <Button variant="tertiary" onClick={() => window.location.assign(link)} fontSize="xs" fullWidth data-test="list-view-button">
        {formatMessage(accessRulesMessages.switchToListView)}
      </Button>
    </Flex>
  )
}
